export function getLevelUp(valueExp) {
  switch (true) {
    case valueExp < 100:
      return {
        level: '0',
        maxLevel: 100
      };
    // case valueExp < 200:
    //   return {
    //     level: '1',
    //     maxLevel: 2000000
    //   }
    // case valueExp < 3000000:
    //   return {
    //     level: '2',
    //     maxLevel: 3000000
    //   }
    // case valueExp < 4000000:
    //   return {
    //     level: '3',
    //     maxLevel: 4000000
    //   }
    // case valueExp < 5000000:
    //   return {
    //     level: '4',
    //     maxLevel: 4000000
    //   }
    // case valueExp < 6000000:
    //   return {
    //     level: '5',
    //     maxLevel: 6000000
    //   }

    default:
      return {
        level: '1',
        maxLevel: 100
      };
  }
}